import React from 'react';

export default {
    id: 3,
    title: 'Augmented Reality for Product Catalogues',
    youtubeUrl: 'https://www.youtube.com/embed/9dyxvvTlubs',
    body: (
        <div>
            <p>Make shopping a lot easier! Link your online shop with your magazine, so that your customer can buy your products right away and doesn’t have to search for your website online. Enhance your retail business with AR and invoke your catalogue to life!</p>
        </div>
    )
}
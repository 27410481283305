import React from 'react';

export default {
    id: 7,
    title: 'Augmented Reality for Print Magazines',
    youtubeUrl: 'https://www.youtube.com/embed/JIBN7dGf3I4',
    body: (
        <div>
            <p>The print product catalogue was used for decades as one of the major communication and marketing materials, and it still works. What about adding interactive effects to it like an AR video? Tell the story behind a shoot for example.</p>
        </div>
    )
}
import React from 'react';
import Showcase001 from "./Showcase001";
import Showcase002 from "./Showcase002";
import Showcase003 from "./Showcase003";
import Showcase004 from "./Showcase004";
import Showcase005 from "./Showcase005";
import Showcase006 from "./Showcase006";
import Showcase007 from "./Showcase007";

const ShowcaseDetailsContent = ({showcaseId}) => {
    const RenderArticle = () => {
        let showcaseNr = parseInt(showcaseId, 10)
        let showcaseData = null;

        switch (showcaseNr) {
            case 1:
                showcaseData = Showcase001
                break
            case 2:
                showcaseData = Showcase002
                break
            case 3:
                showcaseData = Showcase003
                break
            case 4:
                showcaseData = Showcase004
                break
            case 5:
                showcaseData = Showcase005
                break
            case 6:
                showcaseData = Showcase006
                break
            case 7:
                showcaseData = Showcase007
                break
            default:
                showcaseData = null
        }

        return showcaseData ? (
            <div className="row">
                <div className="col-lg-6 col-md-12 yt-video-container">
                    <iframe src={`${showcaseData.youtubeUrl}?controls=0`}
                            title={showcaseData.title}
                            frameBorder={'0'}
                            allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
                            allowFullScreen
                            className={"yt-video"}
                    />
                </div>

                <div className="col-lg-6 col-md-12" style={{padding: '30px'}}>
                    <h2>{showcaseData.title}</h2>
                    {showcaseData.body}
                </div>
            </div>
        ) : null
    }

    return (
        <section className="blog-details-area ptb-100" style={{paddingTop: '150px'}}>
            <div className="container">
                <RenderArticle />
            </div>
        </section>
    )
}

export default ShowcaseDetailsContent
import React from 'react';

export default {
    id: 5,
    title: 'Augmented Reality for Art Installations',
    youtubeUrl: 'https://www.youtube.com/embed/zjP1qEJxZxA',
    body: (
        <div>
            <p>Let your product speak! Enhance your own art. There are endless possibilities to add interactive effects to the product itself. In this example, we enhanced a painting from an artist with multimedia material.</p>
        </div>
    )
}
import React from 'react';

export default {
    id: 2,
    title: 'Augmented Reality for Business Cards',
    youtubeUrl: 'https://www.youtube.com/embed/OXxVPGaXNZA',
    body: (
        <div>
            <p>Use AR for your business and enhance your corporate design materials like logo, business cards, corporate brochures with additional augmented reality material. In this example this personal trainer’s business card was augmented with his image and additional information.</p>
        </div>
    )
}
import React from 'react';

export default {
    id: 4,
    title: 'Augmented Reality for Interactive Learning',
    youtubeUrl: 'https://www.youtube.com/embed/PEfCDjkExaU',
    body: (
        <div>
            <p>Augmented reality in education enables faster learning by interesting additional content which comes as an effect to the printed books, training documents and other educational content.</p>
        </div>
    )
}
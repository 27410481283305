import React from 'react';

export default {
    id: 1,
    title: 'Augmented Reality for Product Packaging',
    youtubeUrl: 'https://www.youtube.com/embed/2EEX5d7EIBo',
    body: (
        <div>
            <p>Use your product packaging in the most efficient way and communicate additional product information and services with AR in retail! Be it a product demonstration video, a cooking recipe, or a construction manual directly in the retail shop or other point of sale.</p>
        </div>
    )
}
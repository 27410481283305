import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import ShowcaseDetailsContent from "../../components/ShowcasesContent/ShowcaseDetailsContent";
import ProjectStartArea from "../../components/Index/ProjectStartArea";

const ShowcaseDetails = ({showcaseId}) => {
    return (
        <Layout>
            <Navbar />
            <ShowcaseDetailsContent showcaseId={showcaseId} />
            <ProjectStartArea />
            <Footer />
        </Layout>
    )
}

export default ShowcaseDetails;
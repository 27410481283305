import React from 'react';

export default {
    id: 6,
    title: 'Augmented Reality for Product Instructions',
    youtubeUrl: 'https://www.youtube.com/embed/ev_VxSnTPwQ',
    body: (
        <div>
            <p>Augment everything with a marker! The targets usually are 2D images. However, you can add a marker as a sticker to any object you want. In this example we added a marker-sticker to a real flowerpot. You can use this scenario for augmented reality in any retail or point of sale situation you can imagine. See the AR experience.</p>
        </div>
    )
}